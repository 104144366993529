import * as actionTypes from './actionTypes';

export function policyRulesRequest() {
  return {
    type: actionTypes.POLICY_RULES_REQUEST,
  };
}

export function policyRulesSuccess(password) {
  return {
    type: actionTypes.POLICY_RULES_SUCCESS,
    password,
  };
}

export function policyRulesFailure(errors) {
  return {
    type: actionTypes.POLICY_RULES_FAIL,
    errors,
  };
}
