import nock from 'nock';
import url from 'url';
import { testSaga } from 'bnc-utilities-js/testUtils/saga';
import browserHistory from '../../../nav/BrowserHistoryManager/index';
import { Configs } from '../../../configs';

import {
  watchRetrieveConfigRequest,
  watchRetrieveConfigSuccess,
} from '../workers';

import ROUTES from '../../../utils/constants/routes';

import {
  CONFIGS_SERVICE_RETRIEVE_CONFIGS_SUCCESS,
  CONFIGS_SERVICE_RETRIEVE_CONFIGS_FAILURE,
} from '../actionTypes';
import { POLICY_RULES_REQUEST } from '../../policyRulesService/actionTypes';

jest.mock('../../../utils/browserUtils', () => ({
  getCurrentQueryString: jest.fn(),
}));

jest.mock('../../../utils/urlUtils', () => ({
  getPartnerId: jest.fn(),
}));

browserHistory.push = jest.fn();

function getConfigServer(response, responseCode) {
  const configServerEndpoint = url.parse(
    Configs.API.ENDPOINT.CONFIG_SERVER.ENDPOINT,
  );
  const origin = `${configServerEndpoint.protocol}//${configServerEndpoint.host}`;
  nock(origin)
    .get(configServerEndpoint.pathname)
    .reply(responseCode, response);
}

jest.mock('react-router');

describe('.watchRetrieveConfigRequest', () => {
  test('.watchRetrieveConfigRequest - Success', async () => {
    const response = {
      status: {
        global: {},
      },
    };

    getConfigServer(response, 200);

    const { sagaDone } = testSaga({
      saga: watchRetrieveConfigRequest,
      state: {
        templateServiceReducer: {
          templateName: 'SBIP2',
        },
      },
      args: [{}],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: CONFIGS_SERVICE_RETRIEVE_CONFIGS_SUCCESS, configs: response },
      { type: POLICY_RULES_REQUEST },
    ]);
  });

  test('.watchRetrieveConfigRequest - Success BNE', async () => {
    const { sagaDone } = testSaga({
      saga: watchRetrieveConfigRequest,
      state: {
        templateServiceReducer: {
          templateName: 'BNE',
        },
      },
      args: [{}],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: POLICY_RULES_REQUEST },
    ]);
  });

  test('.watchRetrieveConfigRequest - Failure', async () => {
    getConfigServer('error.http.503', 503);
    const { sagaDone } = testSaga({
      saga: watchRetrieveConfigRequest,
      state: {},
      args: [{}],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([
      { type: CONFIGS_SERVICE_RETRIEVE_CONFIGS_FAILURE, err: 'error.http.500' },
    ]);
  });
});

describe('.watchRetrieveConfigSuccess', () => {
  test('.watchRetrieveConfigSuccess - active true', async () => {
    const { sagaDone } = testSaga({
      saga: watchRetrieveConfigSuccess,
      state: {},
      args: [
        {
          configs: {
            status: {
              global: {
                options: {
                  unavailable_mode: { active: false },
                  maintenance_mode: { active: false },
                },
              },
            },
          },
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).not.toBeCalled();
  });

  test('.watchRetrieveConfigSuccess - active false', async () => {
    const { sagaDone } = testSaga({
      saga: watchRetrieveConfigSuccess,
      state: {},
      args: [
        {
          configs: {
            status: {
              global: {
                options: {
                  unavailable_mode: { active: true },
                  maintenance_mode: { active: false },
                },
              },
            },
          },
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).toBeCalledWith(ROUTES.COMPLETE_UNAVAILABILITY);
  });

  test('.watchRetrieveConfigSuccess - active false', async () => {
    const { sagaDone } = testSaga({
      saga: watchRetrieveConfigSuccess,
      state: {},
      args: [
        {
          configs: {
            status: {
              global: {
                options: {
                  unavailable_mode: { active: false },
                  maintenance_mode: { active: true },
                },
              },
            },
          },
        },
      ],
    });

    const { actions } = await sagaDone;
    expect(actions).toEqual([]);
    expect(browserHistory.push).toBeCalledWith(ROUTES.COMPLETE_UNAVAILABILITY);
  });
});