import { expectSaga } from 'redux-saga-test-plan';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import IamxApi from 'bnc-react-services/managers/IamxManager/api';

import { policyRulesSuccess, policyRulesFailure } from '../actions';

import policyRulesJsonMock from '../policyRulesMock.json';
import { watchPolicyRequest } from '../workers';

const iamxError = new IamxApi.IamxError('code', 'message');
jest.mock('bnc-react-services/managers/RequestsManager', () => ({
  call: jest.fn(),
}));

test('.watchPolicyRequest -- Success', async () => {
  requestsManager.call.mockImplementation(() => {
    return policyRulesJsonMock;
  });
  await expectSaga(watchPolicyRequest)
    .put(policyRulesSuccess(policyRulesJsonMock.password))
    .run();
});

test('.watchPolicyRequest -- Failure', async () => {
  requestsManager.call.mockImplementation(() => {
    throw iamxError;
  });
  await expectSaga(watchPolicyRequest)
    .put(policyRulesFailure(iamxError))
    .run();
});
