import { getPoliciesPasswordComplexity } from '../selectors';
import { namespace } from '../index';

const mockPolicyRulesReducer = {
  [namespace]: {
    password: {
      complexity: {
        minLength: 8,
        minLowerCase: 1,
        minUpperCase: 0,
        minNumber: 1,
        minSymbol: 0,
        excludeUsername: false,
        dictionary: {
          common: {
            exclude: false,
          },
        },
        excludeAttributes: [],
      },
    },
  },
};

const testArray = [
  {
    expectedResult: mockPolicyRulesReducer[namespace].password.complexity,
    testCase: mockPolicyRulesReducer,
  },
  {
    expectedResult: {},
    testCase: {},
  },
  {
    expectedResult: {},
    testCase: null,
  },
  {
    expectedResult: {},
    testCase: undefined,
  },
];

describe('policyRulesService selectors - getPoliciesPasswordComplexity', () => {
  testArray.forEach(({ testCase, expectedResult }) => {
    it(`should return ${JSON.stringify(
      expectedResult,
    )} for the input ${JSON.stringify(testCase)} `, () => {
      expect(getPoliciesPasswordComplexity(testCase)).toMatchObject(
        expectedResult,
      );
    });
  });
});
