import get from 'lodash/get';
import {
  getPasswordPolicies,
  POLICY_CONTEXT,
  savePasswordPolicies,
} from '../localStorage';
import policyRulesJsonMock from '../policyRulesMock.json';

const complexityKey = `PASSWORD_POLICIES_${POLICY_CONTEXT.SBIP}`;
const complexityValue = get(policyRulesJsonMock, 'password');
const complexityEmptyValue = {};

beforeEach(() => {
  localStorage.clear();
  jest.clearAllMocks();
});

describe('policyRulesService localStorage - getPasswordPolicies', () => {
  it('should be empty since policies are not stored in local storage', () => {
    localStorage.setItem(complexityKey, JSON.stringify(complexityEmptyValue));
    const complexityResult = getPasswordPolicies(POLICY_CONTEXT.SBIP);
    expect(localStorage.getItem).toHaveBeenLastCalledWith(complexityKey);
    expect(complexityResult).toMatchObject(complexityEmptyValue);
  });

  it('should return a value since policies are stored in local storage', () => {
    localStorage.setItem(complexityKey, JSON.stringify(complexityValue));
    const complexityResult = getPasswordPolicies(POLICY_CONTEXT.SBIP);
    expect(localStorage.getItem).toHaveBeenLastCalledWith(complexityKey);
    expect(complexityResult).toMatchObject(complexityValue.complexity);
  });
});

describe('policyRulesService localStorage - savePasswordPolicies', () => {
  it('should return false since empty context and empty complexity', () => {
    const result = savePasswordPolicies('', '');
    expect(result).toEqual(false);
  });

  it('should return false because complexities are already stored in localStorage', () => {
    localStorage.setItem(complexityKey, JSON.stringify(complexityValue));
    const result = savePasswordPolicies(POLICY_CONTEXT.SBIP, complexityValue);
    expect(result).toEqual(false);
  });

  it('should return true because complexities are now successfully stored in localStorage', () => {
    const { __STORE__: localStorageStore } = localStorage;

    localStorage.setItem(complexityKey, JSON.stringify(complexityEmptyValue));
    const result = savePasswordPolicies(POLICY_CONTEXT.SBIP, complexityValue);
    expect(localStorage.setItem).toHaveBeenLastCalledWith(
      complexityKey,
      JSON.stringify(complexityValue),
    );
    expect(localStorageStore[complexityKey]).toBe(
      JSON.stringify(complexityValue),
    );
    expect(result).toEqual(true);
  });
});
