import merge from 'lodash/merge';
import { Configs } from '../../configs';
import HTTP from '../../utils/constants/http';
import { bncFetch } from '../../utils/fetchUtils';

const HEADER_CONTEXT = 'individual';

export default {
  getPolicyRulesSbip: () =>
    bncFetch(`${Configs.API.ENDPOINT.IAMX.GET_POLICY_RULES_SBIP}`, {
      headers: merge({
        method: HTTP.METHODS.GET,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        context: HEADER_CONTEXT,
      }),
    }).then(res => res.json()),
};
