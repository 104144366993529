import get from 'lodash/get';
import { namespace } from './index';
import { POLICY_CONTEXT, getPasswordPolicies } from './localStorage';

export const rootSelector = state => get(state, namespace, {});

export const getPoliciesPasswordComplexity = (
  state,
  context = POLICY_CONTEXT.SBIP,
) =>
  get(
    rootSelector(state),
    'password.complexity',
    getPasswordPolicies(context) || {},
  );
