import React, { useEffect, useRef } from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { Tagging, TrackState } from '@nbc-studio/analytics';

import { TRANSACTION_STATUS } from 'bnc-react-services/managers/AuthManager/constants';

import { buildValidations, decodeHash, validate } from './helpers';
import { getBackendErrorMessage } from '../../../utils/strongPasswordUtils/helpers';
import { queueAnnouncer } from '../../../utils/liveAnnouncer/helper';
import {
  UCS_IDS,
  EVENT_NAMES,
  EVENT_SOURCE,
  EVENT_SOURCE_DETAILS,
  EVENT_IDS,
  STEP_NAMES,
  FORM_IDS,
} from '../../../utils/taggingUtils/constants';
import {
  focusOnComponent,
  isFocusOnInputComponents,
} from '../../../utils/domUtils';
import ResetPassword from './ResetPassword/index';

import './style.scss';

const propTypes = {
  intl: intlShape.isRequired,
  isProcessing: PropTypes.bool,
  backendError: PropTypes.shape({}).isRequired,
  analytics: PropTypes.shape({
    flowId: PropTypes.string,
  }),
  type: PropTypes.string,
  hashLogin: PropTypes.string,
  returnToLogin: PropTypes.func.isRequired,
  resetPasswordRequest: PropTypes.func.isRequired,
  policiesPasswordComplexity: PropTypes.shape({}).isRequired,
  announceMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  type: '',
  hashLogin: '',
  isProcessing: false,
  analytics: {},
};

const ResetPasswordHandler = props => {
  const {
    intl,
    type,
    backendError,
    analytics,
    returnToLogin,
    resetPasswordRequest,
    isProcessing,
    policiesPasswordComplexity,
    hashLogin,
    announceMessage,
  } = props;

  const submitHasError = useRef(false);
  const identification = decodeHash(hashLogin);
  const validations = buildValidations(intl, policiesPasswordComplexity);
  const hasRequestError = !isEmpty(backendError);
  const isTempPassword = type === TRANSACTION_STATUS.PASSWORD_EXPIRED;
  const backendErrorMessage = getBackendErrorMessage(
    backendError,
    isTempPassword,
  );

  useEffect(() => {
    if (hasRequestError) {
      queueAnnouncer(
        [intl.formatMessage({ id: backendErrorMessage })],
        announceMessage,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRequestError, intl]);
  useEffect(() => {
    focusOnComponent(`#newPassword`);
  });

  // Form data & labels
  const content = {
    title: intl.formatMessage({ id: 'text.title.passwordChange' }),
    message:
      type === TRANSACTION_STATUS.PASSWORD_EXPIRED
        ? null
        : intl.formatMessage({ id: 'text.message.identificationSuccess' }),
    description:
      type === TRANSACTION_STATUS.PASSWORD_EXPIRED
        ? intl.formatMessage({ id: 'text.message.temporaryPassword' })
        : null,
    instructionMessage: intl.formatMessage({
      id: 'text.message.instructionMessage',
    }),
    requestError: intl.formatMessage({
      id: backendErrorMessage,
    }),
    newPasswordLabel: intl.formatMessage({ id: 'text.field.editNewPassword' }),
    newPasswordError: intl.formatMessage({
      id: 'text.error.newPasswordInvalid',
    }),
    newPasswordRequired: intl.formatMessage({
      id: `text.error.newPasswordRequired`,
    }),
    newPasswordPlaceHolder: intl.formatMessage({
      id: 'text.field.editNewPasswordPlaceholder',
    }),
    newPasswordAriaLabelShow: intl.formatMessage({
      id: 'text.aria.displayPassword',
    }),
    newPasswordAriaLabelHide: intl.formatMessage({
      id: 'text.aria.hidePassword',
    }),
    passwordStrengthInformationTitle: intl.formatMessage({
      id: 'text.field.passwordStrengthInformationTitle',
    }),
    passwordStrengthInformationMessage: intl.formatMessage({
      id: 'text.field.passwordStrengthInformationMessage',
    }),
    passwordConfirmationLabel: intl.formatMessage({
      id: 'text.field.passwordConfirmation',
    }),
    passwordConfirmationPlaceHolder: intl.formatMessage({
      id: 'text.field.passwordConfirmationPlaceholder',
    }),
  };

  // Form status
  const formStatus = {
    hasCurrentPassword: false,
    hasRequestError,
    isProcessing,
    submitHasError,
  };

  // Formik functions
  const handleOnSubmit = values => {
    resetPasswordRequest(
      values.newPassword,
      isTempPassword,
      identification.password,
    );
  };

  // functions
  const handleOnValidate = values => {
    let errors = validate(
      values,
      false,
      get(validations, 'validationArray'),
      identification.email,
    );
    // clone new password error for criteria list component
    if (Array.isArray(errors.newPassword)) {
      errors = { ...errors, listCriteria: [...errors.newPassword] };
    }
    if (isFocusOnInputComponents() && !submitHasError.current) {
      // avoid password error when we are still editing
      errors = omit(errors, [
        'newPasswordConfirmation',
        'newPassword',
        'newPasswordEmpty',
      ]);
    }
    if (
      values.passwordStrength &&
      !isFocusOnInputComponents() &&
      !errors.newPassword
    ) {
      const passwordStrengthMessage = [
        get(content, 'passwordStrengthInformationTitle'),
        get(content, 'passwordStrengthInformationMessage'),
      ].join('.');
      queueAnnouncer([passwordStrengthMessage], announceMessage);
    }
    submitHasError.current = false;
    return errors;
  };
  const onSubmitTrigger = (values, validateForm) => {
    const errors = validate(
      values,
      false,
      get(validations, 'validationArray'),
      identification.email,
    );
    // submit if no errors
    if (isEmpty(errors)) return true;
    validateForm();
    if (errors.newPasswordConfirmation) {
      focusOnComponent('#newPasswordConfirmation');
    } else if (errors.newPassword) {
      focusOnComponent('#newPassword');
    }
    submitHasError.current = true;
    return false;
  };
  const functions = {
    onValidate: handleOnValidate,
    onPreSubmit: onSubmitTrigger,
    onSubmit: handleOnSubmit,
  };

  return (
    <Tagging
      ucsId={UCS_IDS.FORGOT_PASSWORD}
      eventName={EVENT_NAMES.STEPS}
      eventSource={EVENT_SOURCE.SBIP2}
      eventSourceDetail={EVENT_SOURCE_DETAILS.FORGOT_PASSWORD}
      eventId={EVENT_IDS.ST_FORGOT_PASSWORD}
      stepName={STEP_NAMES.NEW_PASSWORD}
      stepId={3}
      flowId={analytics.flowId}
      formId={FORM_IDS.FORGOT_PASSWORD}
    >
      <ResetPassword
        formStatus={formStatus}
        content={content}
        criteriaMap={get(validations, 'criteriaMap')}
        functions={functions}
        resetForm={returnToLogin}
        announceMessage={announceMessage}
      />
      <TrackState />
    </Tagging>
  );
};

ResetPasswordHandler.propTypes = propTypes;
ResetPasswordHandler.defaultProps = defaultProps;
export default ResetPasswordHandler;
