// @flow
import type { Saga } from 'redux-saga';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import upperCase from 'lodash/upperCase';
import toUpper from 'lodash/toUpper';
import size from 'lodash/size';
import format from 'date-fns/format';

import { fork, put, select, delay } from 'redux-saga/effects';
import { toggleFeatureApi } from 'bnc-react-components';

import requestsManager from 'bnc-react-services/managers/RequestsManager';
import propagateGlobalErrors from 'bnc-react-services/utils/propagateErrorsHandler';
import { BNC_AUTH_CHANGE_PASSWORD_COMPLETED } from 'bnc-react-services/services/AuthService/actionTypes';
import {
  createRootSagaFromWorkersMapping,
  createWorkersMapping,
} from 'bnc-utilities-js/saga';

import browserHistory from '../../nav/BrowserHistoryManager';
import { CONFIGS_NAMES, ROUTES } from '../../utils/constants';
import {
  EXCEPTION_TRIGGER_MAPPING,
  EXCEPTION_TYPES,
  LOCKED_OUT_TOO_MUCH_TRIES,
} from '../../utils/constants/forgotPassword';
import {
  RESEND_SUCCESS_MESSAGE_TIMEOUT,
  RESEND_TIMEOUT,
} from '../../utils/constants/login';
import {
  buildFactors,
  getAgreementIdNumber,
  hasExpiryDateForSheriff,
  hasOriginalAmountForSheriff,
  hasPhoneForSheriff,
} from '../../utils/forgotPasswordUtils';
import { FORGOT_PASSWORD_FIELDS } from '../../utils/forgotPasswordUtils/constants';
import { ACCESS_TOKEN_JSON } from '../../utils/tokenUtils';
import { getAllConfigs } from '../configsService/selectors';
import { resetRecaptcha } from '../forgotPasswordFormService/actions';
import { getProductType } from '../forgotPasswordFormService/selectors';
import {
  loginRouteForgotPasswordException,
  loginRouteRequestLocked,
} from '../loginRouteService/actions';
import {
  displayResend,
  formUpdate as multiFactorAuthFormUpdate,
  hideResend,
  resetMfaCode,
  submitFailure,
  updateFactors,
} from '../multiFactorAuthFormService/actions';
import { formValidation } from '../multiFactorAuthFormService/formSchema';
import {
  getFactorTypes,
  getMFAFormData,
  getSelectedFactor,
} from '../multiFactorAuthFormService/selectors';
import {
  forgotPasswordTrackResendCode,
  resendOTCFailure,
  saveRecaptchaToken,
  setAnalytics,
  setBackendError,
  setPasswordFail,
  setPasswordSuccess,
  showResendOTCSuccessMessage,
  triggerException,
  triggerMFAChoiceStep,
  validateUserFailure,
  validateUserSuccess,
  verifyOTCFailure,
  verifyOTCSuccess,
} from './actions';
import * as actionTypes from './actionTypes';
import {
  forgotPasswordValidateUserFetch,
  sendOTC,
  setPasswordFetch,
  verifyOTC,
} from './api';
import { factorsSelector, getTokenRecaptcha } from './selectors';
import {
  watchTaggingForgotPasswordEvents,
  watchTrackResendCodeSucess,
} from './taggingWorkers';
import type {
  ForgotPasswordTriggerExceptionAction,
  ValidateUserRequestAction,
  ValidateUserSuccessAction,
} from './types';
import {
  FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
  FORGOT_PASSWORD_FORM_INIT_DONE,
  FORGOT_PASSWORD_FORM_UPDATE,
} from '../forgotPasswordFormService/actionTypes';
import { FLOW_IDS } from '../../utils/taggingUtils/constants';
import {
  FORGOT_PASSWORD_CODE_TECHNICAL_ERROR,
  FORGOT_PASSWORD_PREFIX_VERIFY_USER,
} from '../../utils/authErrorMessages';
import { saveHashLogin, updateLockedStatus } from '../loginService/actions';
import { hashLogin } from '../loginService/helper';
import { getPoliciesPasswordComplexity } from '../policyRulesService/selectors';
import { FORGOT_PASSWORD_TRACK_RESEND_SUCCESS } from './actionTypes';
import { getLocale } from '../i18nService/selectors';

function* handleGlobalError(e) {
  yield requestsManager.call(propagateGlobalErrors, e);
  const exceptionMessage = get(e, 'message');
  const exceptionType = EXCEPTION_TRIGGER_MAPPING[exceptionMessage];
  if (exceptionType) {
    // trigger corresponding action depending on exception message.
    yield put(triggerException(exceptionType));
  }
}

function* watchValidateUserRequest(
  action: ValidateUserRequestAction,
): Saga<void> {
  try {
    const {
      productType,
      contextId,
      tokenRecaptcha,
      dateOfBirth,
      cardNumber,
      email,
      expiryDate: expiryDateToFormat,
      accountNumber,
      postalCode,
      originalAmount: originalAmountToTake,
      phone: phoneToSend,
    } = action;
    yield put(setBackendError({}));
    const agreementIdNumber = getAgreementIdNumber(
      cardNumber,
      accountNumber,
      productType,
    );

    const expiryDate =
      expiryDateToFormat && hasExpiryDateForSheriff(productType)
        ? format(expiryDateToFormat, 'yyyy-MM-dd')
        : undefined;

    const originalAmount = hasOriginalAmountForSheriff(productType)
      ? originalAmountToTake
      : undefined;

    const phone = hasPhoneForSheriff(productType) ? phoneToSend : undefined;
    const identification = hashLogin(email, '');
    const locale = yield select(getLocale);

    const { factors } = yield requestsManager.call(
      forgotPasswordValidateUserFetch,
      get(tokenRecaptcha, ACCESS_TOKEN_JSON),
      {
        productType,
        contextId,
        dateOfBirth,
        email,
        agreementIdNumber,
        postalCode,
        originalAmount,
        expiryDate,
        phone,
      },
      locale,
    );

    if (isEmpty(factors)) {
      // even request is successful executed, no factors still mean failure
      const throwObj = {
        code: FORGOT_PASSWORD_CODE_TECHNICAL_ERROR,
        errorIdPrefix: FORGOT_PASSWORD_PREFIX_VERIFY_USER,
      };
      throw throwObj;
    }

    yield put(saveRecaptchaToken(tokenRecaptcha));
    yield put(saveHashLogin(identification));

    // Show the MFA choice step if more then 1 factor
    if (size(factors) > 1) yield put(triggerMFAChoiceStep(factors));
    // Else, send validation code directly and show validation code screen
    else yield put(validateUserSuccess(factors, factors[0].factorType));
  } catch (e) {
    yield put(validateUserFailure(e));
    yield put(resetRecaptcha());
    yield handleGlobalError(e);
  }
}

function* watchTriggerVerificationStep(action) {
  const factors = yield select(factorsSelector);
  yield put(validateUserSuccess(factors, action.factor.factorType));
}

function* watchValidateUserSuccess(
  action: ValidateUserSuccessAction,
): Saga<void> {
  const token = yield select(getTokenRecaptcha);
  const locale = yield select(getLocale);

  const { factors, selectedFactor, contact } = buildFactors(
    action.factors,
    action.selectedFactor,
  );

  yield put(updateFactors(factors, selectedFactor, contact));
  try {
    yield requestsManager.call(
      sendOTC,
      selectedFactor,
      get(token, ACCESS_TOKEN_JSON),
      locale,
    );
    yield fork(createTaskTriggerDisplayResend);
  } catch (e) {
    yield handleGlobalError(e);
    yield put(setBackendError(e));
    // if could not send OTC, display resend OTC option right away
    yield put(displayResend());
  }
}

export function* createTaskTriggerDisplayResend() {
  yield delay(RESEND_TIMEOUT);
  yield put(displayResend());
}

export function* createTaskHideResendSuccessMessage() {
  yield delay(RESEND_SUCCESS_MESSAGE_TIMEOUT);
  yield put(showResendOTCSuccessMessage(false));
}

function* watchTriggerException(
  action: ForgotPasswordTriggerExceptionAction,
): Saga<void> {
  switch (action.exceptionType) {
    case EXCEPTION_TYPES.ACCOUNT_LOCKED:
      yield put(updateLockedStatus(LOCKED_OUT_TOO_MUCH_TRIES));
      yield put(loginRouteRequestLocked());
      break;
    default:
      yield put(loginRouteForgotPasswordException());
  }
}

export function* watchForgotPasswordType(action: ValidateUserRequestAction) {
  const product = yield select(getProductType);
  const flowId = get(FLOW_IDS, product);
  yield put(
    setAnalytics({
      flowId,
    }),
  );
}

function* watchResendOTC({ factor: factorParam }) {
  const token = yield select(getTokenRecaptcha);
  const factor = get(factorParam, 'factorType', factorParam);
  const locale = yield select(getLocale);

  yield put(hideResend());
  yield fork(createTaskTriggerDisplayResend);
  yield put(setBackendError({}));
  // empty and trigger focus on code input
  yield put(resetMfaCode());

  try {
    yield requestsManager.call(
      sendOTC,
      factor,
      get(token, ACCESS_TOKEN_JSON),
      locale,
    );
    yield put(showResendOTCSuccessMessage(true));
    yield fork(createTaskHideResendSuccessMessage);

    const factors = yield select(factorsSelector);
    let contact = null;

    for (const { factorType, value } of factors) {
      if (factorType === upperCase(factor)) {
        contact = value;
        break;
      }
    }

    if (contact) {
      const factorTypes = yield select(getFactorTypes);
      yield put(updateFactors(factorTypes, factor, contact));
    }
    yield put(forgotPasswordTrackResendCode(factor));
  } catch (e) {
    yield handleGlobalError(e);
    yield put(setBackendError(e));
    yield put(resendOTCFailure());
  }
}

function* watchVerifyOTC() {
  // ForgotPasswordMockMode
  // yield put(verifyOTCSuccess());
  // return;

  const token = yield select(getTokenRecaptcha);
  const formData = yield select(getMFAFormData);
  const selectedFactor = yield select(getSelectedFactor);
  const locale = yield select(getLocale);

  const validationResult = yield requestsManager.call(
    formValidation,
    formData,
    selectedFactor,
  );
  if (!validationResult.formValid) {
    yield put(submitFailure(formData.code, validationResult.formattedErrors));
    yield put(verifyOTCFailure());
    return;
  }

  const { code } = formData;

  try {
    yield requestsManager.call(
      verifyOTC,
      toUpper(selectedFactor),
      code,
      get(token, ACCESS_TOKEN_JSON),
      locale,
    );
    yield put(verifyOTCSuccess());
  } catch (e) {
    yield put(
      multiFactorAuthFormUpdate(
        FORGOT_PASSWORD_FIELDS.VALIDATION_FORM.CODE,
        '',
      ),
    );
    yield handleGlobalError(e);
    yield put(setBackendError(e));
    yield put(submitFailure(null, {}));
    yield put(verifyOTCFailure());
  }
}

export function* watchSetPasswordRequest({ newPassword }) {
  try {
    const token = yield select(getTokenRecaptcha);
    const locale = yield select(getLocale);

    yield requestsManager.call(
      setPasswordFetch,
      get(token, ACCESS_TOKEN_JSON),
      newPassword,
      locale,
    );
    yield put(setPasswordSuccess());
  } catch (e) {
    yield put(setPasswordFail());
    yield handleGlobalError(e);
    yield put(setBackendError(e));
  }
}

export function* watchForgotPasswordUnavailableCheck({
  routeToValidate: route,
}) {
  const configs = yield select(getAllConfigs);
  const isForgotPasswordAvailable = toggleFeatureApi.isAvailable(
    configs,
    CONFIGS_NAMES.FORGOT_PASSWORD.GLOBAL,
    [],
  );
  const complexity = yield select(getPoliciesPasswordComplexity);
  const hasNoPolicyRules = isEmpty(complexity);
  const isForgotOrResetPasswordRoute =
    route === ROUTES.FORGOT_PASSWORD || route === ROUTES.RESETPASSWORD;

  if (
    isForgotOrResetPasswordRoute &&
    (!isForgotPasswordAvailable || hasNoPolicyRules)
  ) {
    browserHistory.push(
      `${ROUTES.FORGOT_PASSWORD_UNAVAILABLE}${window.location.search}`,
    );
  } else if (
    isForgotPasswordAvailable &&
    route === ROUTES.FORGOT_PASSWORD_UNAVAILABLE &&
    !hasNoPolicyRules
  ) {
    browserHistory.push(`${ROUTES.FORGOT_PASSWORD}${window.location.search}`);
  }
}

const workersMapping = createWorkersMapping()
  .takeLatest(
    actionTypes.FORGOT_PASSWORD_TRIGGER_EXCEPTION,
    watchTriggerException,
  )
  .takeLatest(
    actionTypes.FORGOT_PASSWORD_VALIDATE_USER_REQUEST,
    watchValidateUserRequest,
  )
  .takeLatest(
    [
      FORGOT_PASSWORD_FORM_INIT_DONE,
      actionTypes.FORGOT_PASSWORD_SEND_ANALYTICS,
      actionTypes.FORGOT_PASSWORD_SET_PASSWORD_SUCCESS,
      BNC_AUTH_CHANGE_PASSWORD_COMPLETED,
      FORGOT_PASSWORD_FORM_FIELD_VALIDATION_FAILURE,
    ],
    watchTaggingForgotPasswordEvents,
  )
  .takeLatest(
    actionTypes.FORGOT_PASSWORD_VALIDATE_USER_SUCCESS,
    watchValidateUserSuccess,
  )
  .takeLatest(
    [FORGOT_PASSWORD_FORM_INIT_DONE, FORGOT_PASSWORD_FORM_UPDATE],
    watchForgotPasswordType,
  )
  .takeLatest(actionTypes.FORGOT_PASSWORD_VERIFY_OTC, watchVerifyOTC)
  .takeLatest(actionTypes.FORGOT_PASSWORD_RESEND_OTC, watchResendOTC)
  .takeLatest(actionTypes.FORGOT_PASSWORD_SET_PASSWORD, watchSetPasswordRequest)
  .takeLatest(
    actionTypes.FORGOT_PASSWORD_UNAVAILABLE_CHECK,
    watchForgotPasswordUnavailableCheck,
  )
  .takeLatest(
    actionTypes.TRIGGER_VERIFICATION_CODE_STEP,
    watchTriggerVerificationStep,
  )
  .takeLatest(FORGOT_PASSWORD_TRACK_RESEND_SUCCESS, watchTrackResendCodeSucess);

export default createRootSagaFromWorkersMapping(workersMapping);
