import {
  createWorkersMapping,
  createRootSagaFromWorkersMapping,
} from 'bnc-utilities-js/saga';

import * as actionTypes from './actionTypes';
import * as workers from './workers';

export const workersMapping = createWorkersMapping().takeLatest(
  actionTypes.POLICY_RULES_REQUEST,
  workers.watchPolicyRequest,
);

export default createRootSagaFromWorkersMapping(workersMapping);
