import testReducer from 'bnc-utilities-js/testUtils/reducer';

import policyRulesReducer from '../reducer';

import {
  policyRulesRequest,
  policyRulesFailure,
  policyRulesSuccess,
} from '../actions';

testReducer(policyRulesReducer)
  .initialState()
  .expectDiff({
    password: {},
    errors: {},
    isFetching: false,
    hasFailed: false,
  });

testReducer(policyRulesReducer)
  .withAnyState()
  .on(policyRulesRequest())
  .expectDiff({
    isFetching: true,
    errors: {},
    hasFailed: false,
  });

testReducer(policyRulesReducer)
  .withAnyState()
  .on(policyRulesFailure('ERROR_MSG'))
  .expectDiff({
    isFetching: false,
    errors: 'ERROR_MSG',
    hasFailed: true,
  });

testReducer(policyRulesReducer)
  .withAnyState()
  .on(policyRulesSuccess('PASSWORD'))
  .expectDiff({
    isFetching: false,
    password: 'PASSWORD',
    errors: {},
    hasFailed: false,
  });
