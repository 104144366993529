import {
  POLICY_RULES_SUCCESS,
  POLICY_RULES_FAIL,
  POLICY_RULES_REQUEST,
} from './actionTypes';

export const initialState = () => ({
  password: {},
  errors: {},
  isFetching: false,
  hasFailed: false,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case POLICY_RULES_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: {},
        hasFailed: false,
      };

    case POLICY_RULES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        password: action.password,
        errors: {},
        hasFailed: false,
      };

    case POLICY_RULES_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: action.errors,
        hasFailed: true,
      };

    default:
      return state;
  }
};
