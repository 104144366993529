import React from 'react';
import cx from 'classnames';

import {
  BASE_RESOURCES_LOGIN_IMAGE_URL,
  DEFAULT_LOGIN_IMAGE_NAME,
} from '../../../utils/constants/login';

import './style.scss';

type Props = {
  className?: string,
  isVisible?: boolean,
};

const defaultProps = {
  className: '',
  isVisible: true,
};

function BncMedia(props: Props) {
  const { className, isVisible } = props;

  if (!isVisible) {
    return null;
  }

  const loginPict = `${BASE_RESOURCES_LOGIN_IMAGE_URL}/bne/${DEFAULT_LOGIN_IMAGE_NAME}`;

  return (
    <div className={cx('bnc-media', className)}>
      <div
        className="image-login-background"
        style={{ backgroundImage: `url(${loginPict})` }}
      />
    </div>
  );
}

BncMedia.defaultProps = defaultProps;

export default BncMedia;
