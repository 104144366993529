import { put } from 'redux-saga/effects';
import get from 'lodash/get';
import requestsManager from 'bnc-react-services/managers/RequestsManager';
import { POLICY_CONTEXT, savePasswordPolicies } from './localStorage';

import { policyRulesSuccess, policyRulesFailure } from './actions';
import policyRulesJsonMock from './policyRulesMock.json';
import API from './api';

const policyRulesMockMode = false;

// eslint-disable-next-line import/prefer-default-export
export function* watchPolicyRequest() {
  try {
    if (policyRulesMockMode) {
      yield put(policyRulesSuccess(get(policyRulesJsonMock, 'password')));
      return;
    }
    const response = yield requestsManager.call(API.getPolicyRulesSbip);
    yield put(policyRulesSuccess(response.password));
    savePasswordPolicies(POLICY_CONTEXT.SBIP, response.password);
  } catch (error) {
    yield put(policyRulesFailure(error));
  }
}
