import React, { lazy, Suspense, createElement } from 'react';
import ReactDOM from 'react-dom';

import 'intl';

// Redux integration
import { Provider } from 'react-redux';

// Internationalization
import { addLocaleData } from 'react-intl';
import { IntlProvider } from 'react-intl-redux';

import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import { registerHook } from '@nbc-studio/analytics';
import { Configs } from './configs';
import LiveAnnouncer from './molecules/common/LiveAnnouncer/index';

// Routes
import Routes from './nav/Routes';

// WhiteLabels
import AppHead from './atoms/AppHead';

import stripConsoleLogs from './utils/stripConsoleLogs';

// Init Style
import './index.scss';

// Biometry
import Biometry from './services/biometryService/biometry';
import registerBiometryHandler from './services/biometryService/handler';

import { isIE11 } from './utils/browserUtils';
import configureStore from './globalRedux/store/configureStore';
import { initialize } from './uma/assembler';
import { trimTrackAction } from './utils/taggingUtils/helper';

// Datadog RUM
import initDatadogRum from './utils/datadogOptions';

initDatadogRum();

stripConsoleLogs(Configs.LOG_LEVEL);

addLocaleData([...en, ...fr]);

async function bootstrap() {
  // Creating store
  const { services } = await initialize();
  const { store, template, history } = configureStore(services);

  if (Biometry.available()) {
    registerBiometryHandler(store);
  }

  // Chatbot Lazy Loader
  const chatbotLazyLoader = () => {
    const isCompatible = !isIE11();
    if (!isCompatible) {
      return null;
    }

    const Chatbot = lazy(() => import('./molecules/chatbot'));
    registerHook('trimTrackAction', trimTrackAction());

    return (
      <Suspense fallback={<div />}>
        <Chatbot
          partnerId={template.partnerId}
          selectedTemplate={template.selectedTemplate}
        />
      </Suspense>
    );
  };

  // Root application
  const Root = () => (
    <Provider store={store}>
      <IntlProvider>
        <div>
          <LiveAnnouncer />
          <AppHead partnerId={template.partnerId} />
          <Routes
            history={history}
            store={store}
            selectedTemplate={template.selectedTemplate}
            partnerId={template.partnerId}
          />
          {chatbotLazyLoader()}
        </div>
      </IntlProvider>
    </Provider>
  );
  return { default: Root };
}

ReactDOM.render(
  <Suspense fallback={null}>{createElement(lazy(bootstrap))}</Suspense>,
  document.getElementById('root'),
);
