// @flow
import React, { useState } from 'react';
import cx from 'classnames';
import type { IntlShape } from 'react-intl';
import { Heading } from '@bnc-ui/heading';
import { Button } from '@bnc-ui/button';
import { openWebUrl } from '../../../utils/browserUtils';

import { getMessageKey } from '../../../utils/templateUtils';
import { Configs } from '../../../configs';
import TEMPLATES from '../../../utils/constants/template';

import './style.scss';
import ClickTaggingWrapper from '../../bne/TaggingBNE/ClickTaggingWrapper';

type Props = {
  intl: IntlShape,
  className?: string,
  isVisible?: boolean,
  loginRouteRequestSignUp: () => void,
  templateName: string,
  locale: string,
  theme?: string,
};

const defaultProps = {
  className: '',
  isVisible: true,
  theme: '',
};

function SignUp(props: Props) {
  const {
    intl,
    className,
    isVisible,
    loginRouteRequestSignUp,
    templateName,
    locale,
    theme,
  } = props;

  const signUpMsgKey = getMessageKey(templateName, 'text.message.signUp');
  const signUpButtonKey = getMessageKey(templateName, 'text.button.signUp');
  const signUpAriaKey = getMessageKey(templateName, 'text.aria.signUp');
  const signUpTitleKey = getMessageKey(templateName, 'text.title.signUp');

  const [buttonClicked, setButtonClicked] = useState(false);

  if (!isVisible) {
    return null;
  }

  const onSignUpRequest = () => {
    loginRouteRequestSignUp();
  };

  const signUpClick = () => {
    switch (templateName) {
      case TEMPLATES.BNE:
        setButtonClicked(true);
        openWebUrl(Configs.BNE.EXTERNAL_URLS.REGISTER_URL.URL[locale]);
        break;
      default:
        onSignUpRequest();
        break;
    }
  };

  const ariaSignUpMessage = intl.formatMessage({ id: signUpAriaKey });
  const signUpMessage = intl.formatMessage({ id: signUpMsgKey });
  const interaction = 'BNE-signup';

  return (
    <div className={cx('sign-up', className)}>
      <Heading type="h2" className="sign-up__title">
        {intl.formatMessage({ id: signUpTitleKey })}
      </Heading>
      <p className={cx('sign-up__message', className)}>{signUpMessage}</p>
      <Button
        appearance="secondary"
        size="medium"
        aria-label={ariaSignUpMessage}
        onClick={signUpClick}
        theme={theme}
      >
        {intl.formatMessage({ id: signUpButtonKey })}
      </Button>
      {buttonClicked === true && (
        <ClickTaggingWrapper interaction={interaction} />
      )}
    </div>
  );
}

SignUp.defaultProps = defaultProps;

export default SignUp;
