import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const PASSWORD_POLICIES = 'PASSWORD_POLICIES';

export const POLICY_CONTEXT = {
  SBIP: 'SBIP',
};

export function savePasswordPolicies(context, complexities) {
  if (
    !isEmpty(context) &&
    !isEmpty(complexities) &&
    !isEqual(get(complexities, 'complexity', ''), getPasswordPolicies(context))
  ) {
    localStorage.setItem(
      `${PASSWORD_POLICIES}_${context}`,
      JSON.stringify(complexities),
    );
    return true;
  }
  return false;
}

export function getPasswordPolicies(context) {
  const complexities = localStorage.getItem(`${PASSWORD_POLICIES}_${context}`);
  const complexity = complexities ? JSON.parse(complexities) : {};
  return get(complexity, 'complexity', {});
}
